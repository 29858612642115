<template>
  <div>
    <transition name="fade">
      <div id="showAllCategory" v-show="showAllCategory" class="py-3">
        <h5 id="headerText" class="weight-bold">دسته بندی ها</h5>
        <div class="card">
          <div
            class=" headingOne card-header my-1 pr-3 d-flex justify-content-between"
          >
            <router-link
              to="/products"
              @click.native="close"
              class="mainRoute d-flex my-auto text-color-444  font-weight-bold"
            >
              <span
                class="circle-list bg-color-theme rounded-circle mt-1 ml-3"
              ></span>
              همه دسته بندی ها
            </router-link>
          </div>
        </div>
        <div  v-for="(item, index) in homeData.categories" :key="item.id" class="card">
          <div
            class=" headingOne card-header my-1 pr-3 d-flex justify-content-between"
          >
            <router-link
              :to="{
                name: 'product.category',
                params: { category: item.id, slug: getSlug(item) },
                
              }"
              @click.native="close"
              class="mainRoute d-flex my-auto text-color-444  font-weight-bold"
            >
              <span
                class="circle-list bg-color-theme rounded-circle mt-1 ml-3"
              ></span>
              {{ item.title }}
            </router-link>
            <div
              @click="changeAngle(item.id)"
              v-show="item.children.length != 0"
              class="showMoreBtn collapsed"
              data-toggle="collapse"
              :data-target="'#mobileMenuChild' + item.id"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              <button type="button">
                <!-- <span>دسته 1</span> -->
                <i
                  :id="'angle' + item.id"
                  class="fa fa-angle-down"
                  aria-hidden="true"
                ></i>
              </button>
            </div>
          </div>

          <SubAllCategory :item="item" @close="close" />
        </div>
      </div>
    </transition>
    <div
      @click="closeCategoryList()"
      v-show="showAllCategory"
      id="closeAllCategory"
    ></div>
  </div>
</template>

<script>
import SubAllCategory from "./SubAllCategory";
export default {
  name: "AllCategory",
  components: { SubAllCategory },
  data() {
    return {
      showAllCategory: this.value,
    };
  },
  computed: {
    homeData() {
      let homeData = this.$store.getters["front/getHomeData"];
      return homeData ? homeData : [];
    },
  },
  mounted() {
    window.addEventListener("popstate", this.checkBackButton);
  },
  beforeDestroy() {
    window.removeEventListener("popstate", this.checkBackButton);
  },
  methods: {
    getSlug(item){
      let Text=item.slug?item.slug:item.title;
      return Text.toLowerCase()
             .replace(/ /g, '-')
             .replace(/[^\w-]+/g, '');
    },
    checkBackButton() {
      if (!this.$route.fullPath.includes("#all-category-modal")) {
        this.closeCategoryList();
      }
    },
    closeCategoryList() {
      this.showAllCategory = false;
      this.$router
        .replace(this.$route.fullPath.replace("#all-category-modal", ""))
        .catch((e) => {});
    },
    // تابع زیر برای تعویض ایکن هنگام باز کردن زیر منو است
    changeAngle(id) {
      let el = document.getElementById("angle" + id);
      if (el.classList.contains("fa-angle-down")) {
        el.className = "fa fa-angle-up";
      } else {
        el.className = "fa fa-angle-down";
      }
    },
    close() {
      this.closeCategoryList();
    },
  },
  props: { value: { Boolean } },
  watch: {
    showAllCategory(newVal) {
      this.$emit("input", newVal);
    },
    "$route.path"() {
      this.closeCategoryList();
    },
    value(newVal) {
      this.showAllCategory = newVal;
      if (newVal) {
        this.$router.push(this.$route.fullPath + "#all-category-modal");
      }
    },
  },
};
</script>

<style scoped>
.fade-enter-active {
  transition: all 0.2s ease-in;
}

.fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.7, 1, 1);
}

.fade-enter,
.fade-leave-to {
  transform: translatey(10px);
  opacity: 0;
}

#showAllCategory {
  position: fixed;
  top: 20px;
  width: 90%;
  margin-right: 5%;
  z-index: 1000 !important;
  background-color: white;
  margin-left: 5%;
  max-height: 78%;
  overflow: auto;
  box-shadow: 0 9px 20px grey;
  border-radius: 12px;
}

.headingOne {
  margin: 0 !important;
}

#headerText {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0;
  height: 40px;
  /* text-shadow: 1px 3px silver; */
}

#closeAllCategory {
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
  background-color: #00000033;
}

/* با استفاده از کد زیر یک سطر  دسته بندی به محتوا زیر تقسیم میشود  */
.showMoreBtn {
  background-color: transparent !important;
  flex-grow: 11;
  display: flex;
  justify-content: flex-end;
}

.mainRoute {
  flex-grow: 1;
}

#showAllCategory .card {
  background-color: unset;
  border: unset;
}

#showAllCategory .card-header {
  background-color: unset;
  border: unset;
}
.showMoreBtn > button,
#showAllCategory >>> .all-categories-sub-menu button {
  background: transparent;
  border-radius: 50%;
  border: 1px solid #666;
  width: 25px;
  height: 25px;
}

.showMoreBtn > button > i,
#showAllCategory >>> .all-categories-sub-menu button > i {
  transform: translateY(4px);
}
</style>
