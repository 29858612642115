import {sortVarieties} from '@/libraries/functions'

/**
 * @property {Number} id
 * @property {String} title,
 * @property {String} short_description
 * @property {Number} unit_price
 * @property {Number} purchase_price
 * @property {String} discount_type
 * @property {Number} discount
 * @property {String} SKU
 * @property {String} barcode
 * @property {Number} brand_id
 * @property {Number} unit_id
 * @property {String} meta_description
 * @property {String} meta_title
 * @property {Number} low_stock_quantity_warning
 * @property {Number} show_quantity
 * @property {String} status
 * @property {String} created_at
 * @property {String} updated_at
 * @property {Array} images
 * @property {Number} price
 * @property {Boolean} chargeable
 * @property {String} slug
 * @property {Array} active_flash
 * @property {[{
 *     id: Number,
 *     title: String,
 *     parent_id: Number,
 *      status: Boolean,
 *      special: Boolean,
 *     priority: Number,
 *     en_title: String,
 *     products_count: Number,
 *     image: { url: String },
 *     icon: {url: String},
 *   }]} categories
 *   @property {{
 *     id: Number,
 *     name: String,
 *     symbol: String,
 *     precision: Number,
 *     status: Boolean
 *   }} unit
 *   @property {[{
 *    id: Number,
 *    attributes: [
 *      id, name, pivot
 *    ],
 *    price: Number,
 *    purchase_price: Number,
 *    product_id: Number,
 *    discount_type: String,
 *    discount: Number,
 *    quantity: Number,
 *    color_id: Number,
 *    color: {
 *    id: Number,
 *    name: String,
 *    code: String
 *    },
 *    images: [{url: String}],
 *    final_price: {discount_model: String, discount_type: String, discount: Number, discount_price: Number, amount: Number}
 *   }]} varieties
 *   @property {[{
 *     id: Number,
 *     title: String,
 *     start_date: String,
 *     end_date: String
 *   }]} active_flash
 *
 *
 *    My custom properties( not from server )
 *   @property {[{
 *     id: Number,
 *     valueId: Number,
 *     value: String
 *   }]} selected_attributes_id
 *   @property {Number} wanted_quantity
 */
export default class product {
    constructor(data, component) {
        this.hadActionWithSize = false; // آیا تا الان سایزی انتخاب کرده
        this.component = component
        this.selected_attributes_id = [];
        this.selected_color_id = null;
        for (let [index] of Object.entries(data)) {
            this[index] = data[index]
        }
        this.varieties = this.varieties.map(v => {
            v.product.images = [...this.images];

            return v;
        })
        this.wanted_quantity = 1;
    }

    /**
     *
     * @returns {*[]}
     */
    getAttributes() {
        let attributes = [];

        this.varieties.forEach(variety => {
            variety.attributes.forEach(attribute => {
                let find = attributes.findIndex((attr => {
                    return attr.id == attribute.id;
                }));
                if (find > -1) {
                    // push the value if not already exists
                    let findAttributeValue = attributes[find].values.findIndex(val => {
                        return val.value == attribute.pivot.value
                    })
                    if (findAttributeValue == -1) {
                        attributes[find].values.push({
                            id: attribute.id,
                            valueId: attribute.pivot.attribute_value_id,
                            value: attribute.pivot.value,
                        })
                    }
                } else {
                    attributes.push(attribute);
                    attributes[attributes.length - 1].values = [{
                        id: attribute.id,
                        valueId: attribute.pivot.attribute_value_id,
                        value: attribute.pivot.value,
                    }]
                }
            })
        })

        return attributes;
    }

    getAttribute(id) {
        return this.getAttributes().find(a => a.id == id)
    }

    /**
     *
     * @returns {*[]}
     */
    getColors() {
        let colors = [];

        [...this.varieties].sort((a,b) => (b.quantity > 0) - (a.quantity > 0)).forEach(variety => {
            if (!variety.color) {
                return;
            }
            const alreadyExists = colors.findIndex(color => {
                return color.id == variety.color_id
            }) > -1;
            if (!alreadyExists) {
                colors.push(variety.color);
            }
        })

        return colors;
    }


    isColorAvailable(color) {
        return true
        color = typeof color === 'object' && color ? color.id : color;

        return this.isAvailable(color, this.selected_attributes_id);
    }

    /**
     *
     * @param {{id: Number, value: String, valueId: Number}} attribute
     */
    isAttributeAvailable(attribute) {
        attribute = this.normalizeAttribute(attribute)
        let selectedAttributes = [...this.selected_attributes_id]
        selectedAttributes = selectedAttributes.filter(selected_attribute => {
            return selected_attribute.id != attribute.id;
        });
        selectedAttributes.push(attribute);

        return this.isAvailable(this.selected_color_id, selectedAttributes)
    }

    /**
     * Turn full attribute to => {id, value, valueId}
     */
    normalizeAttribute(attribute) {
        if (!attribute) {
            return null;
        }
        if (attribute.id && attribute.value && typeof attribute.valueId != 'undefined') {
            return attribute;
        }
        console.log(attribute)
        return {
            id: attribute.id,
            value: attribute.pivot.value,
            valueId: attribute.pivot.attribute_value_id
        }
    }

    /*
     * آیا این تنوع وجود دارد
     */
    isAvailable(colorId, attributes) {
        colorId = typeof colorId === 'object' && colorId ? colorId.id : colorId;
        // اول تنوع هایی که این رنگ و ویژگی هارو دارن انتخاب میکنیم و اگر بینشون حتی یکی موجود بود میگیم موجوده
        let varieties = this.varieties.filter(v => {
            let sameColor = !colorId ? true : v.color_id == colorId;
            let ok = true;
            attributes.forEach(selectedAttribute => {
                // اگر حتی یکی از ویژگی های انتخاب شده توی این تنوع نبود پس این تنوع ما نیست
                let ok2 = false;
                v.attributes.forEach(attr => {
                    if (selectedAttribute.id == attr.id && attr.pivot.value == selectedAttribute.value &&
                        attr.pivot.attribute_value_id == selectedAttribute.valueId) {
                        ok2 = true
                    }
                });
                if (!ok2) {
                    ok = false;
                }
            });
            return sameColor && ok && (v.quantity > 0)
        });

        return varieties.length > 0;
    }

    getAvailableVarieties() {
        return this.varieties.filter(v => {
            return v.quantity > 0;
        })
    }

    /*
     * Select color or attribute
     */
    selectColor(color) {
        this.selected_color_id = typeof color === 'object' && color ? color.id : color;
        this.onSelect('color', this.selected_color_id);
    }

    selectAttribute(id, value, valueId) {
        let attr = this.getAttribute(id)
        if (attr.name == 'size' && !this.is_auto_selecting) {
            this.hadActionWithSize = true;
        }
        this.selected_attributes_id = this.selected_attributes_id.filter((attr) => {
            return attr.id != id;
        })
        this.selected_attributes_id.push({
            id,
            value,
            valueId
        })
        if (!this.is_auto_selecting) {
            this.onSelect('attribute', id);
        }
    }

    deselectAttribute(id) {
        this.selected_attributes_id = this.selected_attributes_id.filter((attr) => {
            return attr.id != id;
        })
    }

    /*
     * this method will get called if a color or attribute been selected
     */
    onSelect(type, id) {
        this.deselectIfNotAvailable(type, id)
    }

    deselectIfNotAvailable(type, id) {
        if (!this.isColorAvailable(this.selected_color_id)) {
            this.selected_color_id = null;
        }
        let deselectedAttributes = [];
        this.selected_attributes_id.forEach(selectedAttribute => {
            if (!this.isAttributeAvailable(selectedAttribute)) {
                deselectedAttributes.push(selectedAttribute);
            }
        })

        let deselectedColor = this.getColors().find(c => c.id == this.selected_color_id);

        // اولین ایراد رو گیر نمیدیم!
        if (deselectedAttributes.length && this.hadActionWithSize) {
            if (this.hadActionWithSize) {
                let additionalMessage =  ' با ';
                let first = true
                let attributes = this.getAttributes()
                deselectedAttributes.forEach(item => {
                    let attr = attributes.find(_attr => _attr.id == item.id)
                    additionalMessage += (!first ? ' و ' : '') + attr.label + ' ' + item.value;
                    first = false
                })
                if (deselectedColor) {
                    additionalMessage += (!first ? ' و ' : '') + 'رنگ ' + deselectedColor.name;
                    first = false
                }
                this.component.$root.notify('متاسفانه از این ' + (additionalMessage ? 'محصول' : 'تنوع')
                    + ' ' + additionalMessage +' فعلا موجود نداریم!', 'warning')
            }
        } else if (deselectedAttributes.length) {
            this.selectFirstIfNotSelectedOf(this.selected_attributes_id, 'size')
            deselectedAttributes = [];
        }
        this.selected_attributes_id = this.selected_attributes_id.filter(selectedAttribute => {
            return !deselectedAttributes.find(item => item.id == selectedAttribute.id)
        })
    }

    selectFirstIfNotSelected() {
        if (this.getSelectedVariety()) {
            return
        }
        this.selectVariety(this.varieties[0])
    }

    selectFirstIfNotSelectedOf(selected_attributes_id = null, skip = []) {
        return ; // وقتی محصول موجود نباشه الکی یکی انتخاب میکنه که جالب نیست
        selected_attributes_id = selected_attributes_id ? selected_attributes_id : this.selected_attributes_id
            let skippingIds = this.getAttributes().filter(attr => skip.includes(attr.name)).map(attr => attr.id)
        selected_attributes_id = selected_attributes_id.filter((val) => {
            return !skippingIds.includes(val.id)
        })
        let attributesToFilter = [];
        selected_attributes_id.forEach((selectedAttributeId) => {
            let attribute = this.getAttribute(selectedAttributeId.id)
            if (!skip.includes(attribute.name)) {
                attributesToFilter.push({
                    id: selectedAttributeId.id, value: selectedAttributeId.value
                })
            }
        })

        let varieties = this.varieties.filter(variety => {
            let ok = true;
            attributesToFilter.forEach((selected) => {
                if (!product.varietyHasAttributeWithValue(variety, selected.id, selected.value)) {
                    ok = false;
                }
            })
            return ok && variety.quantity > 0
        })
        if (!varieties.length) {
            return console.log('Notice: 416419');
        }
        this.selectVariety(varieties[0])
    }

    selectVariety(variety) {
        if (!variety) {
            console.error('IMPORTANT: 232145')
            return;
        }
        this.selected_color_id = variety.color_id;
        this.selected_attributes_id = [];
        this.is_auto_selecting = true; // نشون میده کاربر انتخاب نمیکنه
        variety.attributes.forEach(attr=> {
            this.selectAttribute(attr.id, attr.pivot.value, attr.pivot.attribute_value_id)
        });
        this.is_auto_selecting = false;
    }

    /**
     * @returns {{id: Number, price: Number, purchase_price: Number, product_id: Number, discount_type: String,
     * discount: Number, quantity: Number, color_id: Number, color: {id: Number, name: String, code: String},
     * images: {url: String}[], final_price: {discount_model: String, discount_type: String,
     * discount: Number, discount_price: Number, amount: Number}}|null}
     * esme vizhegi midi tuye araye bejaye skip va un vizhegiro dar nazar nemigire
     */
    getSelectedVariety(skip = false, all = false, selectedColorId = null, selectedAttributesId = null) {
        selectedColorId = selectedColorId ? selectedColorId : this.selected_color_id;
        selectedAttributesId = selectedAttributesId ? selectedAttributesId : this.selected_attributes_id
        if (selectedColorId || selectedAttributesId) {
            return this.varieties[all ? 'filter': 'find']((variety) => {
                let sameColor = variety.color_id == selectedColorId;
                let sameAttributes = true;
                variety.attributes.forEach(attr => {
                    if (skip && skip.includes(attr.name)) {
                        return;
                    }
                    let ok2 = false;
                    selectedAttributesId.forEach(selectedAttribute => {
                        if (selectedAttribute.id == attr.id && attr.pivot.value == selectedAttribute.value &&
                            attr.pivot.attribute_value_id == selectedAttribute.valueId) {
                            ok2 = true
                        }
                    })
                    if (!ok2) {
                        sameAttributes = false;
                    }
                })

                return sameColor && sameAttributes;
            })
        } else {
            // Check if product has a fake variety
            let fakeVariety = this.hasFakeVariety()
            if (fakeVariety) {
                return all ? [fakeVariety] : fakeVariety
            }
        }

        return null;
    }

    findVarietyForAttributeValue(attrId, value) {
        return this.varieties.find((variety) => {
            let sameAttributes = false;
            if (!variety.images.length) {
                return
            }
            variety.attributes.forEach(attr => {
                if (attr.id == attrId && attr.pivot.value == value) {
                    sameAttributes = true
                }
            })

            return sameAttributes;
        })
    }

    isSelectedVarietyInCart() {
        const selectedVariety = this.getSelectedVariety();
        if (!selectedVariety) {
            return false;
        }

        return this.component.$store.getters['front/getHomeData'].user.cart.hasVariety(selectedVariety);
    }

    /**
     * Accepts both id and object
     * @returns {boolean}
     * @param colorId
     */
    isColorSelected(colorId) {
        colorId = typeof colorId === 'object' && colorId ? colorId.id : colorId;

        return this.selected_color_id == colorId
    }

    async addToCart() {
        const variety = this.getSelectedVariety();
        
        if (!variety) {
            throw new Error('SELECT-VARIETY');
        }
        if (typeof this.wanted_quantity === 'undefined' || this.wanted_quantity === '' || this.wanted_quantity === null) {
            throw new Error('SELECT-QUANTITY');
        }
        try {
            const response = await this.component.$store.dispatch('front/addToCart',
                {variety, quantity: this.wanted_quantity, product: this });
            return response
        } catch (error) {
            throw error
        }
    }

    // بیتشرین تعداد تمام تنوع ها
    getMaxQuantity() {
        let max = -1;
        this.varieties.forEach(v => {
            if (v.quantity > max) {
                max = v.quantity
            }
        })
        return max;
    }

    hasFakeVariety() {
        if (this.varieties.length === 1 && product.isFakeVariety(this.varieties[0])) {
            return this.varieties[0];
        }

        return false;
    }

    static isFakeVariety(variety) {
        return !variety.color_id && variety.attributes.length === 0
    }

    // وقتی نمیدونیم تنوعش چیه یک قیمت و معیار نشون میدیم. این اونه
    getMainPrices() {
        let selectedVariety = this.getSelectedVariety();
        let mainVariety;
        if (selectedVariety) {
            mainVariety = selectedVariety;
        } else {
            mainVariety = sortVarieties(this)[0];
        }
        return {
            final_price: mainVariety.final_price.amount,
            price: mainVariety.price, // قیمت بدون تخفیف
            discount_type: mainVariety.final_price.discount_type,
            discount: mainVariety.final_price.discount,
            discount_price: mainVariety.final_price.discount_price
        }
    }

    // تنها تنوع موجود در محصول و انتخاب کن
    selectTheOnlyVariety() {
        if (this.varieties.length > 1) {
            throw new Error('More than 1 variety');
        }
        let singleVariety = this.varieties[0];
        if (singleVariety.color_id) {
            this.selected_color_id = singleVariety.color_id
        }
        let attributes = this.getAttributes();
        attributes.forEach(attribute => {
            this.selected_attributes_id.push(attribute.values[0]); // قطعا یک مقدار داریم برای هر ویژگی وگرنه تنوع ها بیشتر از یکی میشد
        })
    }

    static getVarietyTitle(variety) {
        if (variety.color_id != null || variety.attributes != '') {
            let title = ''
            if (variety.color_id != null) {
                title = `رنگ : ${variety.color.name} | `
            }
            if (variety.attributes != '') {
                variety.attributes.forEach(item => {
                    title = title + `${item.label} : ${item.pivot.value} | `
                })
            }
            let lastSeperatorIndex = title.lastIndexOf('|')
            title = title.substr(0, lastSeperatorIndex) + title.substr(lastSeperatorIndex + 1, title.lengths);
            return title
        } else {
            return ''
        }
    }

    static varietyHasAttributeWithValue(variety, attributeId, value) {
        let ok = false;
        variety.attributes.forEach(attr => {
            if (attr.id == attributeId && attr.pivot.value == value) {
                ok = true;
            }
        })
        return ok;
    }

    // گرفتن عکس اصلی یک توع از روی محصول بدون اینه اون تنوع عکسی داشته باشه
    static getVarietyUniqueKey(variety, skip = []) {
        let key = 'c' + variety.color_id;
        variety.attributes.forEach(attribute => {
            if (skip.includes(attribute.name)) {
                return;
            }
            key += '|' + attribute.id + attribute.name + attribute.pivot.value;
        })
        return key;
    }

    static getImagesForVariety(variety, skip = []) {
        let varietyKey = this.getVarietyUniqueKey(variety, skip);
        let product = variety.product;
        let okVarieties = []
        product.varieties.forEach(v => {
            if (this.getVarietyUniqueKey(v, skip) === varietyKey) {
                okVarieties.push(v)
            }
        })
        let images = [];
        okVarieties.forEach(v => {
            v.images.forEach(img => images.push(img))
        })
        return images
    }
}
