<template>
  <!--      data-parent="#accordionExample"-->
  <div
    v-show="item.children.length != 0"
    :id="'mobileMenuChild' + item.id"
    class="sub-all-categories-side-btn text-right collapse"
    aria-labelledby="headingOne"
  >
    <div class="card-body py-0">
      <ul class="all-categories-sub-menu pr-3">
        <li  v-for="(child, index) in item.children" :key="child.id">
          <div>
            <router-link
              :to="{
                name: 'product.category',
                params: { category: child.id, slug: getSlug(child)  },
              }"
              @click.native="$emit('close')"
            >
              {{ child.title }}
            </router-link>
            <div
              @click="changeAngle(child.id)"
              class="showMoreBtn collapsed"
              data-toggle="collapse"
              :data-target="'#mobileMenuChild' + child.id"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              <button v-show="child.children.length != 0" type="button">
                <i
                  data-v-38cbe804=""
                  :id="'angle' + child.id"
                  aria-hidden="true"
                  class="fa fa-angle-down"
                ></i>
              </button>
            </div>
          </div>
          <SubAllCategory
            v-if="child.children && child.children.length"
            :item="child"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "SubAllCategory",
  props: {
    item: Object,
  },
  methods: {
    changeAngle(id) {
      this.$parent.changeAngle(id);
    },
    getSlug(item){
      let Text=item.slug?item.slug:item.title;
      return Text.toLowerCase()
             .replace(/ /g, '-')
             .replace(/[^\w-]+/g, '');
    },
  },
};
</script>

<style scoped>
.all-categories-sub-menu .showMoreBtn {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.all-categories-sub-menu > li > div:first-child {
  display: flex;
  padding: 10px;
  justify-content: space-between;
  padding-left: 30px;
}

.all-categories-sub-menu {
  list-style-type: none;
  border-right: 1px solid var(--color-ea);
}

.all-categories-sub-menu a {
  color: var(--color-444);
  transition: 0.3s;
  font-size: 15px;
  padding: 0;
}

.all-categories-sub-menu a:hover,
.all-categories-side a:hover {
  color: var(--color-theme);
}
</style>
